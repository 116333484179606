
import Vue from 'vue';
import config from "@/config";

export default Vue.extend({
    name: 'crosswise',
    components: {},
    data() {
        return {
            loading:true,
            isPrimary:true,
            onReachFlag:true,
            title:'先进典型',
            isZoomed:false,
            params:{ server:'1',category_id:'',event_id:'',page:1,limit:8},
            total:0,
            events: [],
            details:{},
          }
    },
    created() {
        this.postList(this.$route.query.classify as string);
        if(this.$route.query.classify  == '13') this.title = '先进典型'
        else if(this.$route.query.classify  == '14') this.title = '英雄人物'
        else this.title = '没有了'
    },
    mounted() {
        const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
        const scrollHeight = scrollingDiv.scrollTop;
        scrollingDiv.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        getFullImagePath(relativePath: string) {
            return `${config.domain}${relativePath}`;
        },
        openimg(event) {
            this.details = event
            // console.log(this.details)
            this.isZoomed = !this.isZoomed
        },
        goBack(){
            this.$router.replace({path:'/home'});
        },
        async postList(classify) {
            try {
                this.params.category_id = classify
                const result = await this.$api.postList(this.params); 
                this.events = [...this.events, ...result.data.list];
                this.total =  result.data.total
                this.loading = false
                this.isPrimary = false
            } catch (error) {
                console.log('error', error)
            }
        },
        handleScroll() {
            const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
            const scrollHeight = scrollingDiv.scrollTop;
            const pageHeight = this.params.page * 80
            // console.log("scrollHeight",scrollHeight,"pageHeight",pageHeight)
            if (scrollHeight > pageHeight){
                // 显示底部提示
                const limitlen = this.total / this.params.limit
                // 判断是否有数据
                if ( this.params.page >= limitlen){
                    this.onReachFlag = false
                    return;
                }else {
                    console.log("还有数据")
                    this.onReachFlag = true
                }
                this.params.page ++
                this.postList(this.$route.query.classify as string);
            }
        },
    },
    beforeDestroy() {
        // 移除滚动事件监听，以避免内存泄漏
        window.removeEventListener('scroll', this.handleScroll);
    }
});
